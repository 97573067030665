/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import axios from 'axios'
import { mapKeys, snakeCase } from 'lodash'
import { getToken, setToken } from '@/utils/auth'

// Create axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 60000 // Request timeout in second (15000 => 15s)
})

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.authorization = `Bearer ${token}` // Set JWT token
    }

    if (!(config.data instanceof FormData)) {
      // ex: accountNumber => account_number
      config.data = mapKeys(config.data, (val, key) => snakeCase(key))
      if (config.data.hasOwnProperty('details')) {
        config.data.details = config.data.details.map(prop => mapKeys(prop, (val, key) => snakeCase(key)))
      }
      if (config.data.hasOwnProperty('approvers')) {
        config.data.approvers = config.data.approvers.map(prop => mapKeys(prop, (val, key) => snakeCase(key)))
      }
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response pre-processing
service.interceptors.response.use(
  response => {
    if (response.headers.authorization) {
      setToken(response.headers.authorization)
      response.data.token = response.headers.authorization
    }

    return response.data
  },
  error => {
    let message = !error.response ? 'Error: Network Error' : error.response.data.message
    if (!error.response) {
      message = 'Error: Network Error'
    } else if (error.response.data.message) {
      message = error.response.data.message
    } else {
      message = 'Error: Network Error'
    }
    // console.log(error)
    // if (error.response.status === 401) {
    //   message = 'Your session is end'
    // }
    return Promise.reject({ error, message })
  }
)

export default service
