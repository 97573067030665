// import useJwt from '@/auth/jwt/useJwt'
import { getToken } from '@/utils/auth'
import store from '@/store'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => getToken()

export const redirectLoggedInUser = () => (getToken() ? '/' : { name: 'login' })

export const isUserHaveCompany = () => store.state.user.companies.length > 0
