import { subjectAbility } from '@/constant'

/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multi language
 */

export default [
  {
    path: '/profile',
    name: 'apps-profile',
    component: () => import('@/views/apps/profile/Profile.vue'),
    meta: {
      pageTitle: 'apps.profile.moduleName',
      action: 'read',
      resource: subjectAbility.General
    }
  },
  {
    path: '/profile-user',
    name: 'profile-user',
    component: () => import('@/views/apps/profile/ProfileUser.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      
      resource: subjectAbility.General
    }
  }
]
