import { subjectAbility } from '@/constant'

/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multilanguage
 */

export default [
  {
    path: '/master-data/chart-of-account',
    name: 'apps-master-data-coa-list',
    component: () => import('@/views/apps/master-data/chart-of-account/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataCOA.moduleName',
      action: 'read',
      resource: subjectAbility.MdCoa
    }
  },
  {
    path: '/master-data/chart-of-account/add',
    name: 'apps-master-data-coa-add',
    component: () => import('@/views/apps/master-data/chart-of-account/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataCOA.actions.create',
      action: 'create',
      resource: subjectAbility.MdCoa
    }
  },
  {
    path: '/master-data/chart-of-account/detail/:id',
    name: 'apps-master-data-coa-detail',
    component: () => import('@/views/apps/master-data/chart-of-account/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataCOA.actions.read',
      action: 'read',
      resource: subjectAbility.MdCoa
    }
  },
  {
    path: '/master-data/contact',
    name: 'apps-master-data-contact-list',
    component: () => import('@/views/apps/master-data/contact/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataContact.moduleName',
      action: 'read',
      resource: subjectAbility.MdContact
    }
  },
  {
    path: '/master-data/cashbank',
    name: 'apps-master-data-cashbank-list',
    component: () => import('@/views/apps/master-data/cashbank/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataCashbank.moduleName',
      action: 'read',
      resource: subjectAbility.MdCashbank
    }
  },
  {
    path: '/master-data/cashbank/add',
    name: 'apps-master-data-cashbank-add',
    component: () => import('@/views/apps/master-data/cashbank/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataCOA.actions.create',
      action: 'create',
      resource: subjectAbility.MdCashbank
    }
  },
  {
    path: '/master-data/cashbank/detail/:id',
    name: 'apps-master-data-cashbank-detail',
    component: () => import('@/views/apps/master-data/cashbank/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataCOA.actions.read',
      action: 'read',
      resource: subjectAbility.MdCashbank
    }
  },
  {
    path: '/master-data/coa-main-group-activity',
    name: 'apps-master-data-coa-main-group-activity-list',
    component: () => import('@/views/apps/master-data/coa-main-group-activity/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataMainGroupActivity.moduleName',
      action: 'read',
      resource: subjectAbility.MdCOAMainGroupActivity
    }
  },
  {
    path: '/master-data/coa-main-group-activity/add',
    name: 'apps-master-data-coa-main-group-activity-add',
    component: () => import('@/views/apps/master-data/coa-main-group-activity/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataMainGroupActivity.actions.create',
      action: 'create',
      resource: subjectAbility.MdCOAMainGroupActivity
    }
  },
  {
    path: '/master-data/coa-main-group-activity/detail/:id',
    name: 'apps-master-data-coa-main-group-activity-detail',
    component: () => import('@/views/apps/master-data/coa-main-group-activity/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataMainGroupActivity.actions.read',
      action: 'read',
      resource: subjectAbility.MdCOAMainGroupActivity
    }
  },
  {
    path: '/master-data/coa-group-activity',
    name: 'apps-master-data-coa-group-activity-list',
    component: () => import('@/views/apps/master-data/coa-group-activity/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataGroupActivity.moduleName',
      action: 'read',
      resource: subjectAbility.MdCOAGroupActivity
    }
  },
  {
    path: '/master-data/coa-group-activity/add',
    name: 'apps-master-data-coa-group-activity-add',
    component: () => import('@/views/apps/master-data/coa-group-activity/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataGroupActivity.actions.create',
      action: 'create',
      resource: subjectAbility.MdCOAGroupActivity
    }
  },
  {
    path: '/master-data/coa-group-activity/detail/:id',
    name: 'apps-master-data-coa-group-activity-detail',
    component: () => import('@/views/apps/master-data/coa-group-activity/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataGroupActivity.actions.read',
      action: 'read',
      resource: subjectAbility.MdCOAGroupActivity
    }
  },
  {
    path: '/master-data/coa-activity',
    name: 'apps-master-data-coa-activity-list',
    component: () => import('@/views/apps/master-data/coa-activity/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataActivity.moduleName',
      action: 'read',
      resource: subjectAbility.MdCOAActivity
    }
  },
  {
    path: '/master-data/coa-activity/add',
    name: 'apps-master-data-coa-activity-add',
    component: () => import('@/views/apps/master-data/coa-activity/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataActivity.actions.create',
      action: 'create',
      resource: subjectAbility.MdCOAActivity
    }
  },
  {
    path: '/master-data/coa-activity/detail/:id',
    name: 'apps-master-data-coa-activity-detail',
    component: () => import('@/views/apps/master-data/coa-activity/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataActivity.actions.read',
      action: 'read',
      resource: subjectAbility.MdCOAActivity
    }
  },
  {
    path: '/master-data/contact',
    name: 'apps-master-data-contact-list',
    component: () => import('@/views/apps/master-data/contact/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataContact.moduleName',
      action: 'read',
      resource: subjectAbility.MdContact
    }
  },
  {
    path: '/master-data/contact/add',
    name: 'apps-master-data-contact-add',
    component: () => import('@/views/apps/master-data/contact/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataContact.actions.create',
      action: 'create',
      resource: subjectAbility.MdContact
    }
  },
  {
    path: '/master-data/contact/detail/:id',
    name: 'apps-master-data-contact-detail',
    component: () => import('@/views/apps/master-data/contact/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataContact.actions.read',
      action: 'read',
      resource: subjectAbility.MdContact
    }
  },
  {
    path: '/master-data/employee',
    name: 'apps-master-data-employee-list',
    component: () => import('@/views/apps/master-data/employee/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataEmployee.moduleName',
      action: 'read',
      resource: subjectAbility.MdEmployee
    }
  },
  {
    path: '/master-data/employee/add',
    name: 'apps-master-data-employee-add',
    component: () => import('@/views/apps/master-data/employee/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataEmployee.actions.create',
      action: 'create',
      resource: subjectAbility.MdEmployee
    }
  },
  {
    path: '/master-data/employee/detail/:id',
    name: 'apps-master-data-employee-detail',
    component: () => import('@/views/apps/master-data/employee/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataEmployee.actions.read',
      action: 'read',
      resource: subjectAbility.MdEmployee
    }
  },
  {
    path: '/master-data/user',
    name: 'apps-master-data-user-list',
    component: () => import('@/views/apps/master-data/user/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataUser.moduleName',
      action: 'read',
      resource: subjectAbility.MdUser
    }
  },
  {
    path: '/master-data/user/detail/:id',
    name: 'apps-master-data-user-detail',
    component: () => import('@/views/apps/master-data/user/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataUser.actions.read',
      action: 'read',
      resource: subjectAbility.MdUser
    }
  },
  {
    path: '/master-data/unit',
    name: 'apps-master-data-unit-list',
    component: () => import('@/views/apps/master-data/unit/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataUnit.moduleName',
      action: 'read',
      resource: subjectAbility.MdUnit
    }
  },
  {
    path: '/master-data/unit/add',
    name: 'apps-master-data-unit-add',
    component: () => import('@/views/apps/master-data/unit/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataUnit.actions.create',
      action: 'create',
      resource: subjectAbility.MdUnit
    }
  },
  {
    path: '/master-data/unit/detail/:id',
    name: 'apps-master-data-unit-detail',
    component: () => import('@/views/apps/master-data/unit/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataUnit.actions.read',
      action: 'read',
      resource: subjectAbility.MdUnit
    }
  },
  {
    path: '/master-data/product-brand',
    name: 'apps-master-data-product-brand-list',
    component: () => import('@/views/apps/master-data/product-brand/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductBrand.actions.create',
      action: 'read',
      resource: subjectAbility.MdProductBrand
    }
  },
  {
    path: '/master-data/product-brand/add',
    name: 'apps-master-data-product-brand-add',
    component: () => import('@/views/apps/master-data/product-brand/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductBrand.actions.create',
      action: 'create',
      resource: subjectAbility.MdProductBrand
    }
  },
  {
    path: '/master-data/product-brand/detail/:id',
    name: 'apps-master-data-product-brand-detail',
    component: () => import('@/views/apps/master-data/product-brand/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductBrand.actions.read',
      action: 'read',
      resource: subjectAbility.MdProductBrand
    }
  },
  {
    path: '/master-data/product-category',
    name: 'apps-master-data-product-category-list',
    component: () => import('@/views/apps/master-data/product-category/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductCategory.moduleName',
      action: 'read',
      resource: subjectAbility.MdProductCategory
    }
  },
  {
    path: '/master-data/product-category/add',
    name: 'apps-master-data-product-category-add',
    component: () => import('@/views/apps/master-data/product-category/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductCategory.actions.create',
      action: 'create',
      resource: subjectAbility.MdProductCategory
    }
  },
  {
    path: '/master-data/product-category/detail/:id',
    name: 'apps-master-data-product-category-detail',
    component: () => import('@/views/apps/master-data/product-category/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductCategory.actions.read',
      action: 'read',
      resource: subjectAbility.MdProductCategory
    }
  },
  {
    path: '/master-data/product-sub-category',
    name: 'apps-master-data-product-sub-category-list',
    component: () => import('@/views/apps/master-data/product-sub-category/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductSubCategory.moduleName',
      action: 'read',
      resource: subjectAbility.MdProductSubCategory
    }
  },
  {
    path: '/master-data/product-sub-category/add',
    name: 'apps-master-data-product-sub-category-add',
    component: () => import('@/views/apps/master-data/product-sub-category/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductSubCategory.actions.create',
      action: 'create',
      resource: subjectAbility.MdProductSubCategory
    }
  },
  {
    path: '/master-data/product-sub-category/detail/:id',
    name: 'apps-master-data-product-sub-category-detail',
    component: () => import('@/views/apps/master-data/product-sub-category/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProductSubCategory.actions.read',
      action: 'read',
      resource: subjectAbility.MdProductSubCategory
    }
  },
  {
    path: '/master-data/product',
    name: 'apps-master-data-product-list',
    component: () => import('@/views/apps/master-data/product/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataProduct.moduleName',
      action: 'read',
      resource: subjectAbility.MdProduct
    }
  },
  {
    path: '/master-data/product/add',
    name: 'apps-master-data-product-add',
    component: () => import('@/views/apps/master-data/product/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProduct.actions.create',
      action: 'create',
      resource: subjectAbility.MdProduct
    }
  },
  {
    path: '/master-data/product/detail/:id',
    name: 'apps-master-data-product-detail',
    component: () => import('@/views/apps/master-data/product/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataProduct.actions.read',
      action: 'read',
      resource: subjectAbility.MdProduct
    }
  },
  {
    path: '/master-data/tax',
    name: 'apps-master-data-tax-list',
    component: () => import('@/views/apps/master-data/tax/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataTax.moduleName'
    }
  },
  {
    path: '/master-data/tax/add',
    name: 'apps-master-data-tax-add',
    component: () => import('@/views/apps/master-data/tax/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataTax.actions.create'
    }
  },
  {
    path: '/master-data/tax/detail/:id',
    name: 'apps-master-data-tax-detail',
    component: () => import('@/views/apps/master-data/tax/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataTax.actions.read'
    }
  },
  {
    path: '/master-data/prefix/add',
    name: 'apps-master-data-prefix-add',
    component: () => import('@/views/apps/master-data/prefix/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataPrefix.actions.update'
    }
  },
  {
    path: '/master-data/seaport',
    name: 'apps-master-data-seaport-list',
    component: () => import('@/views/apps/master-data/seaport/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataSeaport.moduleName',
      action: 'read',
      resource: subjectAbility.Seaport
    }
  },
  {
    path: '/master-data/seaport/add',
    name: 'apps-master-data-seaport-add',
    component: () => import('@/views/apps/master-data/seaport/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataSeaport.actions.create',
      action: 'create',
      resource: subjectAbility.Seaport
    }
  },
  {
    path: '/master-data/seaport/detail/:id',
    name: 'apps-master-data-seaport-detail',
    component: () => import('@/views/apps/master-data/seaport/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataSeaport.actions.read',
      action: 'read',
      resource: subjectAbility.Seaport
    }
  },
  {
    path: '/master-data/airport',
    name: 'apps-master-data-airport-list',
    component: () => import('@/views/apps/master-data/airport/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataAirport.moduleName',
      action: 'read',
      resource: subjectAbility.Airport
    }
  },
  {
    path: '/master-data/airport/add',
    name: 'apps-master-data-airport-add',
    component: () => import('@/views/apps/master-data/airport/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataAirport.actions.create',
      action: 'create',
      resource: subjectAbility.Airport
    }
  },
  {
    path: '/master-data/airport/detail/:id',
    name: 'apps-master-data-airport-detail',
    component: () => import('@/views/apps/master-data/airport/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataAirport.actions.read',
      action: 'read',
      resource: subjectAbility.Airport
    }
  },
  {
    path: '/master-data/tag',
    name: 'apps-master-data-tag-list',
    component: () => import('@/views/apps/master-data/tag/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataTag.moduleName',
      action: 'read',
      resource: subjectAbility.MdTag
    }
  },
  {
    path: '/master-data/tag/add',
    name: 'apps-master-data-tag-add',
    component: () => import('@/views/apps/master-data/tag/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataTag.actions.create',
      action: 'create',
      resource: subjectAbility.MdTag
    }
  },
  {
    path: '/master-data/tag/detail/:id',
    name: 'apps-master-data-tag-detail',
    component: () => import('@/views/apps/master-data/tag/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataTag.actions.read',
      action: 'read',
      resource: subjectAbility.MdTag
    }
  },
  {
    path: '/master-data/work-unit',
    name: 'apps-master-data-work-unit-list',
    component: () => import('@/views/apps/master-data/work-unit/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataWorkUnit.moduleName',
      action: 'read',
      resource: subjectAbility.MdWorkUnit
    }
  },
  {
    path: '/master-data/work-unit/add',
    name: 'apps-master-data-work-unit-add',
    component: () => import('@/views/apps/master-data/work-unit/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataWorkUnit.actions.create',
      action: 'create',
      resource: subjectAbility.MdWorkUnit
    }
  },
  {
    path: '/master-data/work-unit/detail/:id',
    name: 'apps-master-data-work-unit-detail',
    component: () => import('@/views/apps/master-data/work-unit/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataWorkUnit.actions.read',
      action: 'read',
      resource: subjectAbility.MdWorkUnit
    }
  },
  // Office
  {
    path: '/master-data/office',
    name: 'apps-master-data-office-list',
    component: () => import('@/views/apps/master-data/office/index.vue'),
    meta: {
      pageTitle: 'apps.masterDataOffice.actions.create',
      action: 'read',
      resource: subjectAbility.MdOffice
    }
  },
  {
    path: '/master-data/office/add',
    name: 'apps-master-data-office-add',
    component: () => import('@/views/apps/master-data/office/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataOffice.actions.create',
      action: 'create',
      resource: subjectAbility.MdOffice
    }
  },
  {
    path: '/master-data/office/detail/:id',
    name: 'apps-master-data-office-detail',
    component: () => import('@/views/apps/master-data/office/Form.vue'),
    meta: {
      pageTitle: 'apps.masterDataOffice.actions.read',
      action: 'read',
      resource: subjectAbility.MdOffice
    }
  }

]
