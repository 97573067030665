import { subjectAbility } from '@/constant'

/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multi language
 */

export default [
  {
    path: '/setting',
    name: 'apps-setting',
    component: () => import('@/views/apps/setting/Setting.vue'),
    meta: {
      pageTitle: 'apps.setting.moduleName',
      action: 'read',
      resource: subjectAbility.General
    }
  },
  {
    path: '/company/user-invitation/activation/:token',
    name: 'company-user-invitation-activation',
    component: () => import('@/views/apps/setting/user-invitation/Activation.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      shouldLogin: false,
      resource: subjectAbility.General
    }
  }
]
