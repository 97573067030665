import { subjectAbility } from '@/constant'

/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multilanguage
 */

export default [
  // MODULE APPROVAL
  {
    path: '/transactions/module-approval',
    name: 'apps-transactions-module-approval-list',
    component: () => import('@/views/apps/transactions/module-approval/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.moduleApproval.moduleName'
    }
  },
  // CASH BANK
  {
    path: '/transactions/cashbank/request',
    name: 'apps-transactions-cashbank-request-list',
    component: () => import('@/views/apps/transactions/cashbank/request/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbRequest.moduleName'
    }
  },
  {
    path: '/transactions/cashbank/request/add',
    name: 'apps-transactions-cashbank-request-add',
    component: () => import('@/views/apps/transactions/cashbank/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbRequest.moduleName'
    }
  },
  {
    path: '/transactions/cashbank/request/:id',
    name: 'apps-transactions-cashbank-request-detail',
    component: () => import('@/views/apps/transactions/cashbank/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbRequest.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank/request-detail/:id',
    name: 'apps-transactions-cashbank-request-approval-detail',
    component: () => import('@/views/apps/transactions/cashbank/request-approval/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbRequest.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank/request-approval',
    name: 'apps-transactions-cashbank-request-approval-list',
    component: () => import('@/views/apps/transactions/cashbank/request-approval/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbRequest.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank',
    name: 'apps-transactions-cashbank-list',
    component: () => import('@/views/apps/transactions/cashbank/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.moduleName'
    }
  },
  {
    path: '/transactions/cashbank/add',
    name: 'apps-transactions-cashbank-add',
    component: () => import('@/views/apps/transactions/cashbank/Form.vue'),
    children: [
      {
        path: 'cash-in/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-cash-in',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createCI',
          action: 'read',
          resource: subjectAbility.TxCashbankAddIn
        }
      },
      {
        path: 'cash-out/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-cash-out',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createCO',
          action: 'read',
          resource: subjectAbility.TxCashbankAddOut
        }
      },
      {
        path: 'bank-in/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-bank-in',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createBI',
          action: 'read',
          resource: subjectAbility.TxCashbankAddIn
        }
      },
      {
        path: 'bank-out/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-bank-out',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createBO',
          action: 'read',
          resource: subjectAbility.TxCashbankAddOut
        }
      }
    ]
  },
  {
    path: '/transactions/cashbank/add/transfer',
    name: 'apps-transactions-cashbank-add-transfer',
    component: () => import('@/views/apps/transactions/cashbank/transfer/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.actions.createTF',
      action: 'read',
      resource: subjectAbility.TxCashbankAddTransfer
    }
  },
  {
    path: '/transactions/cashbank/detail/transfer/:id',
    name: 'apps-transactions-cashbank-detail-transfer',
    component: () => import('@/views/apps/transactions/cashbank/transfer/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.actions.readTF',
      action: 'read',
      resource: subjectAbility.TxCashbankAddTransfer
    }
  },
  {
    path: '/transactions/cashbank/detail/:id/:transactionTypeCode',
    name: 'apps-transactions-cashbank-detail',
    component: () => import('@/views/apps/transactions/cashbank/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashbank
    }
  },

  // DRAFT JOURNAL
  {
    path: '/transactions/draft-journal',
    name: 'apps-transactions-draft-journal-list',
    component: () => import('@/views/apps/transactions/draft-journal/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsDraftJournal.moduleName',
      action: 'read',
      resource: subjectAbility.TxDraftJournal
    }
  },
  {
    path: '/transactions/draft-journal/add',
    name: 'apps-transactions-draft-journal-add',
    component: () => import('@/views/apps/transactions/draft-journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsDraftJournal.actions.create',
      action: 'create',
      resource: subjectAbility.TxDraftJournal
    }
  },
  {
    path: '/transactions/draft-journal/detail/:id',
    name: 'apps-transactions-draft-journal-detail',
    component: () => import('@/views/apps/transactions/draft-journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsDraftJournal.actions.read',
      action: 'read',
      resource: subjectAbility.TxDraftJournal
    }
  },

  // JOURNAL
  {
    path: '/transactions/journal',
    name: 'apps-transactions-journal-list',
    component: () => import('@/views/apps/transactions/journal/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.moduleName',
      action: 'read',
      resource: subjectAbility.TxJournal
    }
  },
  {
    path: '/transactions/journal/add',
    name: 'apps-transactions-journal-add',
    component: () => import('@/views/apps/transactions/journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.actions.create',
      action: 'create',
      resource: subjectAbility.TxJournal
    }
  },
  {
    path: '/transactions/journal/detail/:id',
    name: 'apps-transactions-journal-detail',
    component: () => import('@/views/apps/transactions/journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.actions.read',
      action: 'read',
      resource: subjectAbility.TxJournal
    }
  },
   //JOURNAL TEMPLATE
   {
    path: '/transactions/journal-template',
    name: 'apps-transactions-journalTemplate-list',
    component: () => import('@/views/apps/transactions/journal-template/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournalTemplate.moduleName',
      action: 'read',
      resource: subjectAbility.TxJournalTemplate
    }
  },
  {
    path: '/transactions/journal-template/add',
    name: 'apps-transactions-journalTemplate-add',
    component: () => import('@/views/apps/transactions/journal-template/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournalTemplate.actions.create',
      action: 'create',
      resource: subjectAbility.TxJournal
    }
  },
  {
    path: '/transactions/journal-template/detail/:id',
    name: 'apps-transactions-journalTemplate-detail',
    component: () => import('@/views/apps/transactions/journal-template/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.actions.read',
      action: 'read',
      resource: subjectAbility.TxJournal
    }
  },
  // SALES MODULE
  {
    path: '/transactions/sales/invoice/list',
    name: 'apps-transactions-sales-invoice-list',
    component: () => import('@/views/apps/transactions/sales/invoice/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesInvoice
    }
  },
  {
    path: '/transactions/sales/invoice/add',
    name: 'apps-transactions-sales-invoice-add',
    component: () => import('@/views/apps/transactions/sales/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'create',
      resource: subjectAbility.TxSalesInvoice
    }
  },
  {
    path: '/transactions/sales/invoice/edit/:id',
    name: 'apps-transactions-sales-invoice-detail',
    component: () => import('@/views/apps/transactions/sales/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesInvoice
    }
  },
  {
    path: '/transactions/sales/invoice/prints/:id',
    name: 'apps-transactions-sales-invoice-prints',
    component: () => import('@/views/apps/transactions/sales/invoice/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesInvoice
    }
  },
  // SALES PURCHASE
  {
    path: '/transactions/sales/payment/list',
    name: 'apps-transactions-sales-payment-list',
    component: () => import('@/views/apps/transactions/sales/payment/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesPayment
    }
  },
  {
    path: '/transactions/sales/payment/invoice',
    name: 'apps-transactions-sales-payment-add-per-invoice',
    component: () => import('@/views/apps/transactions/sales/payment/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.payment.moduleName',
      action: 'create',
      resource: subjectAbility.TxSalesPayment
    },
    props: true
  },
  {
    path: '/transactions/sales/payment/detail/:id',
    name: 'apps-transactions-sales-payment-detail',
    component: () => import('@/views/apps/transactions/sales/payment/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesPayment
    }
  },
  // PURCHASE MODULE
  // Purchase Request
  {
    path: '/transactions/purchase/request/list',
    name: 'apps-transactions-purchase-request-list',
    component: () => import('@/views/apps/transactions/purchase/request/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequest
    }
  },
  {
    path: '/transactions/purchase/request/add',
    name: 'apps-transactions-purchase-request-add',
    component: () => import('@/views/apps/transactions/purchase/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequest
    }
  },
  {
    path: '/transactions/purchase/request/detail/:id',
    name: 'apps-transactions-purchase-request-detail',
    component: () => import('@/views/apps/transactions/purchase/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequest
    }
  },
  {
    path: '/transactions/purchase/request-assign-vendor/list',
    name: 'apps-transactions-purchase-request-assign-vendor-list',
    component: () => import('@/views/apps/transactions/purchase/request-assign-vendor/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignVendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignVendor
    }
  },
  {
    path: '/transactions/purchase/request-assign-vendor/add',
    name: 'apps-transactions-purchase-request-assign-vendor-add',
    component: () => import('@/views/apps/transactions/purchase/request-assign-vendor/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignVendor.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequestAssignVendor
    }
  },
  {
    path: '/transactions/purchase/request-assign-vendor/detail/:id',
    name: 'apps-transactions-purchase-request-assign-vendor-detail',
    component: () => import('@/views/apps/transactions/purchase/request-assign-vendor/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignVendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignVendor
    }
  },
  {
    path: '/transactions/purchase/request-assign-price/list',
    name: 'apps-transactions-purchase-request-assign-price-list',
    component: () => import('@/views/apps/transactions/purchase/request-assign-price/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignPrice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignPrice
    }
  },
  {
    path: '/transactions/purchase/request-assign-price/add',
    name: 'apps-transactions-purchase-request-assign-price-add',
    component: () => import('@/views/apps/transactions/purchase/request-assign-price/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignPrice.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequestAssignPrice
    }
  },
  {
    path: '/transactions/purchase/request-assign-price/detail/:id',
    name: 'apps-transactions-purchase-request-assign-price-detail',
    component: () => import('@/views/apps/transactions/purchase/request-assign-price/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignPrice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignPrice
    }
  },
  // Purchase Order
  {
    path: '/transactions/purchase/order/list',
    name: 'apps-transactions-purchase-order-list',
    component: () => import('@/views/apps/transactions/purchase/order/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  {
    path: '/transactions/purchase/order/add',
    name: 'apps-transactions-purchase-order-add',
    component: () => import('@/views/apps/transactions/purchase/order/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  {
    path: '/transactions/purchase/order/detail/:id',
    name: 'apps-transactions-purchase-order-detail',
    component: () => import('@/views/apps/transactions/purchase/order/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  // Purhcase Invoice
  {
    path: '/transactions/purchase/invoice/list',
    name: 'apps-transactions-purchase-invoice-list',
    component: () => import('@/views/apps/transactions/purchase/invoice/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/add',
    name: 'apps-transactions-purchase-invoice-add',
    component: () => import('@/views/apps/transactions/purchase/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/detail/:id',
    name: 'apps-transactions-purchase-invoice-detail',
    component: () => import('@/views/apps/transactions/purchase/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/prints/:id',
    name: 'apps-transactions-purchase-invoice-prints',
    component: () => import('@/views/apps/transactions/purchase/invoice/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  // PURCHASE PAYMENT VOUCHER
  {
    path: '/transactions/purchase/payment-voucher/list',
    name: 'apps-transactions-purchase-payment-voucher-list',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePaymentVoucher
    }
  },
  {
    path: '/transactions/purchase/payment-voucher/invoice',
    name: 'apps-transactions-purchase-payment-voucher-add-per-invoice',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchasePaymentVoucher
    },
    props: true
  },
  {
    path: '/transactions/purchase/payment-voucher/add',
    name: 'apps-transactions-purchase-payment-voucher-add',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePaymentVoucher
    }
  },
  {
    path: '/transactions/purchase/payment-voucher/detail/:id',
    name: 'apps-transactions-purchase-payment-voucher-detail',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePaymentVoucher
    }
  },
  // PURCHASE PAYMENT
  {
    path: '/transactions/purchase/payment/list',
    name: 'apps-transactions-purchase-payment-list',
    component: () => import('@/views/apps/transactions/purchase/payment/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  {
    path: '/transactions/purchase/payment/invoice',
    name: 'apps-transactions-purchase-payment-add-per-invoice',
    component: () => import('@/views/apps/transactions/purchase/payment/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchasePayment
    },
    props: true
  },
  {
    path: '/transactions/purchase/payment/add',
    name: 'apps-transactions-purchase-payment-add',
    component: () => import('@/views/apps/transactions/purchase/payment/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  // Payment with voucher
  {
    path: '/transactions/purchase/payment-per-voucher/add',
    name: 'apps-transactions-purchase-payment-per-voucher-add',
    component: () => import('@/views/apps/transactions/purchase/payment/FormPerVoucher.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  {
    path: '/transactions/purchase/payment/detail/:id',
    name: 'apps-transactions-purchase-payment-detail',
    component: () => import('@/views/apps/transactions/purchase/payment/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },

  // CASH ADVANCE REQUEST APPROVAL
  {
    path: '/transactions/cash-advance/request-approval',
    name: 'apps-transactions-cash-advance-request-approval-list',
    component: () => import('@/views/apps/transactions/cash-advance/request-approval/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequestApproval.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequestApproval
    }
  },
  {
    path: '/transactions/cash-advance/request-approval/add',
    name: 'apps-transactions-cash-advance-request-approval-add',
    component: () => import('@/views/apps/transactions/cash-advance/request-approval/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequestApproval.actions.create',
      action: 'create',
      resource: subjectAbility.TxCashAdvanceRequestApproval
    }
  },
  {
    path: '/transactions/cash-advance/request-approval/detail/:id',
    name: 'apps-transactions-cash-advance-request-approval-detail',
    component: () => import('@/views/apps/transactions/cash-advance/request-approval/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequestApproval.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequestApproval
    }
  },

  // CASH ADVANCE REGISTER
  {
    path: '/transactions/cash-advance',
    name: 'apps-transactions-cash-advance-list',
    component: () => import('@/views/apps/transactions/cash-advance/register/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },
  {
    path: '/transactions/cash-advance/add',
    name: 'apps-transactions-cash-advance-add',
    component: () => import('@/views/apps/transactions/cash-advance/register/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.actions.create',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },
  {
    path: '/transactions/cash-advance/detail/:id',
    name: 'apps-transactions-cash-advance-detail',
    component: () => import('@/views/apps/transactions/cash-advance/register/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },

  // CASH ADVANCE REALISATION
  // {
  //   path: '/transactions/cash-advance/realisation',
  //   name: 'apps-transactions-cash-advance-realisation-list',
  //   component: () => import('@/views/apps/transactions/cash-advance/realisation/Index.vue'),
  //   meta: {
  //     pageTitle: 'apps.transactionsCashAdvance.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.TxCashAdvance
  //   }
  // },
  // {
  //   path: '/transactions/cash-advance/realisation/add',
  //   name: 'apps-transactions-cash-advance-realisation-add',
  //   component: () => import('@/views/apps/transactions/cash-advance/realisation/Form.vue'),
  //   meta: {
  //     pageTitle: 'apps.transactionsCashAdvance.actions.create',
  //     action: 'read',
  //     resource: subjectAbility.TxCashAdvance
  //   }
  // },
  {
    path: '/transactions/cash-advance/realisation/detail/:id',
    name: 'apps-transactions-cash-advance-realisation-detail',
    component: () => import('@/views/apps/transactions/cash-advance/realisation/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },
  // REFUND
  {
    path: '/transactions/cash-advance/refund/detail/:id',
    name: 'apps-transactions-cash-advance-refund-detail',
    component: () => import('@/views/apps/transactions/cash-advance/refund/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.singular.refund',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },

  // CASH ADVANCE REQUEST
  {
    path: '/transactions/cash-advance/request',
    name: 'apps-transactions-cash-advance-request-list',
    component: () => import('@/views/apps/transactions/cash-advance/request/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequest.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequest
    }
  },
  {
    path: '/transactions/cash-advance/request/add',
    name: 'apps-transactions-cash-advance-request-add',
    component: () => import('@/views/apps/transactions/cash-advance/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequest.actions.create',
      action: 'create',
      resource: subjectAbility.TxCashAdvanceRequest
    }
  },
  {
    path: '/transactions/cash-advance/request/detail/:id',
    name: 'apps-transactions-cash-advance-request-detail',
    component: () => import('@/views/apps/transactions/cash-advance/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequest.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequest
    }
  },

  // CLOSING
  {
    path: '/transactions/closing',
    name: 'apps-transactions-closing-list',
    component: () => import('@/views/apps/transactions/closing/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsClosing.moduleName',
      action: 'read',
      resource: subjectAbility.TxClosing
    }
  },
  {
    path: '/transactions/closing/detail/:id',
    name: 'apps-transactions-closing-detail',
    component: () => import('@/views/apps/transactions/closing/Detail.vue'),
    meta: {
      pageTitle: 'apps.transactionsClosing.singular.profitLoss',
      action: 'read',
      resource: subjectAbility.TxClosing
    }
  },

  // BUDGETING
  {
    path: '/transactions/budgeting',
    name: 'apps-transactions-budgeting-list',
    component: () => import('@/views/apps/transactions/Budgeting/Index.vue'),
    meta: {
      pageTitle: 'apps.budgeting.moduleName',
      action: 'read',
      resource: subjectAbility.TxBudgeting
    }
  },
  {
    path: '/transactions/budgeting/add',
    name: 'apps-transactions-budgeting-add',
    component: () => import('@/views/apps/transactions/Budgeting/Form.vue'),
    meta: {
      pageTitle: 'apps.budgeting.moduleName',
      action: 'read',
      resource: subjectAbility.TxBudgeting
    }
  },
  {
    path: '/transactions/budgeting/detail/:id',
    name: 'apps-transactions-budgeting-detail',
    component: () => import('@/views/apps/transactions/Budgeting/Form.vue'),
    meta: {
      pageTitle: 'apps.budgeting.moduleName',
      action: 'read',
      resource: subjectAbility.TxBudgeting
    }
  },

  // CH BACC
  {
    path: '/transactions/ch-bacc/bacc',
    name: 'apps-transactions-chbacc-bacc-list',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'read',
      resource: subjectAbility.BaccTransaction
    }
  },
  {
    path: '/transactions/ch-bacc/bacc/add',
    name: 'apps-transactions-chbacc-bacc-add',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'create',
      resource: subjectAbility.BaccTransaction
    }
  },
  {
    path: '/transactions/ch-bacc/bacc/edit/:id',
    name: 'apps-transactions-chbacc-bacc-edit',
    component: () => import('@/views/apps/transactions/ch-bacc/bacc/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.chbacc.bacc.moduleName',
      action: 'read',
      resource: subjectAbility.BaccTransaction
    }
  }

]
