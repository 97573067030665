import { subjectAbility } from '@/constant'

export default [
  {
    path: '/membership/user/signup',
    name: 'membership-user-signup',
    component: () => import('@/views/apps/membership/signup/SignUp.vue'),
    meta: {
      layout: 'full',
      shouldLogin: false,
      action: 'read',
      resource: subjectAbility.General
    }
  },
  {
    path: '/membership/user/signup-success',
    name: 'membership-user-signup-success',
    component: () => import('@/views/apps/membership/signup/SignUpSuccess.vue'),
    meta: {
      layout: 'full',
      shouldLogin: false,
      action: 'read',
      resource: subjectAbility.General
    }
  },
  {
    path: '/membership/user/activation/:token',
    name: 'membership-user-activation',
    component: () => import('@/views/apps/membership/signup/Activation.vue'),
    meta: {
      layout: 'full',
      shouldLogin: false,
      action: 'read',
      resource: subjectAbility.General
    }
  },
  {
    path: '/membership/company-register',
    name: 'membership-company-register',
    component: () => import('@/views/apps/membership/company-register/Form.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      resource: subjectAbility.General
    }
  }
]
