import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

const options = {
  reverseButtons: true,
  buttonsStyling: false,
  customClass: {
    actions: 'd-flex flex-sm-column flex-md-row justify-content-around'
  }
}

Vue.use(VueSweetalert2, options)
