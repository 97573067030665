import request from '@/utils/request'

export function signIn (data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export function signUp (data) {
  return request({
    url: '/auth/signup',
    method: 'post',
    data
  })
}

export function forgotPassword (data) {
  return request({
    url: '/auth/forgot-password',
    method: 'post',
    data
  })
}

// export function logout() {
//   return request({
//     url: '/auth/logout',
//     method: 'post',
//   })
// }
