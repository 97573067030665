import { subjectAbility } from '@/constant'

export default [
  { path: '/', redirect: { name: 'dashboard-analytics' } },
  {
    path: '/dashboard',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      // pageTitle: 'pages.dashboard.moduleName',
      action: 'read',
      resource: subjectAbility.Dashboard
    }
  }
]
