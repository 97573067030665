import { subjectAbility } from '@/constant'
/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multi language
 */

export default [
  {
    path: '/report/ledger',
    name: 'apps-report-ledger',
    component: () => import('@/views/apps/report/Ledger.vue'),
    meta: {
      pageTitle: 'apps.reportLedger.moduleName',
      action: 'read',
      resource: subjectAbility.ReportLedger
    }
  },
  {
    path: '/report/journal',
    name: 'apps-report-journal',
    component: () => import('@/views/apps/report/Journal.vue'),
    meta: {
      pageTitle: 'apps.reportJournal.moduleName',
      action: 'read',
      resource: subjectAbility.ReportJournal
    }
  },
  {
    path: '/report/trial-balance',
    name: 'apps-report-trial-balance',
    component: () => import('@/views/apps/report/TrialBalance.vue'),
    meta: {
      pageTitle: 'apps.reportTrialBalance.moduleName',
      action: 'read',
      resource: subjectAbility.ReportTrialBalance
    }
  },
  {
    path: '/report/work-sheet',
    name: 'apps-report-work-sheet',
    component: () => import('@/views/apps/report/WorkSheet.vue'),
    meta: {
      pageTitle: 'apps.reportTrialBalance.moduleName',
      action: 'read',
      resource: subjectAbility.ReportWorkSheet
    }
  },
  {
    path: '/report/sales-aging',
    name: 'apps-report-sales-aging',
    component: () => import('@/views/apps/report/SalesAging.vue'),
    meta: {
      pageTitle: 'apps.reportSalesAging.moduleName',
      action: 'read',
      resource: subjectAbility.ReportSalesAging
    }
  },
  {
    path: '/report/sales-aging-detail',
    name: 'apps-report-sales-aging-detail',
    component: () => import('@/views/apps/report/SalesAgingDetail.vue'),
    meta: {
      pageTitle: 'apps.reportSalesAgingDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportSalesAgingDetail
    }
  },
  {
    path: '/report/balance-sheet',
    name: 'apps-report-balance-sheet',
    component: () => import('@/views/apps/report/BalanceSheet.vue'),
    meta: {
      pageTitle: 'apps.reportBalanceSheet.moduleName',
      action: 'read',
      resource: subjectAbility.ReportBalanceSheet
    }
  },
  {
    path: '/report/balance-sheet',
    name: 'apps-report-balance-sheet-per-branch',
    component: () => import('@/views/apps/report/BalanceSheet.vue'),
    meta: {
      pageTitle: 'apps.reportBalanceSheetPerBranch.moduleName',
      action: 'read',
      resource: subjectAbility.ReportBalanceSheetPerBranch
    }
  },
  {
    path: '/report/cash-advance',
    name: 'apps-report-cash-advance',
    component: () => import('@/views/apps/report/CashAdvance.vue'),
    meta: {
      pageTitle: 'apps.reportCashAdvance.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashAdvance
    }
  },
  {
    path: '/report/cash-advance/detail',
    name: 'apps-report-cash-advance-detail',
    component: () => import('@/views/apps/report/CashAdvanceDetail.vue'),
    meta: {
      pageTitle: 'apps.reportCashAdvanceDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashAdvanceDetail
    }
  },
  {
    path: '/report/sales/invoice',
    name: 'apps-report-sales-invoice',
    component: () => import('@/views/apps/report/SalesInvoice.vue'),
    meta: {
      pageTitle: 'apps.reportSalesInvoice.moduleName',
      action: 'read',
      resource: subjectAbility.ReportSalesInvoice
    }
  },
  {
    path: '/report/cash-bank',
    name: 'apps-report-cash-bank',
    component: () => import('@/views/apps/report/CashBank.vue'),
    meta: {
      pageTitle: 'apps.reportCashBank.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashBank
    }
  },
  {
    path: '/report/purchase/aging',
    name: 'apps-report-purchase-aging',
    component: () => import('@/views/apps/report/PurchaseAging.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseAging.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseAging
    }
  },
  {
    path: '/report/purchase/aging-detail',
    name: 'apps-report-purchase-aging-detail',
    component: () => import('@/views/apps/report/PurchaseAgingDetail.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseAgingDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseAgingDetail
    },
    props: route => ({
      dateUntil: route.query.dateUntil,
      contactId: route.query.contactId,
      tags: route.query.tags
    })
  },
  {
    path: '/report/purchase/invoice',
    name: 'apps-report-purchase-invoice',
    component: () => import('@/views/apps/report/PurchaseInvoice.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseInvoice.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseInvoice
    }
  },
  // BUDGETING
  // {
  //   path: '/report/budgeting',
  //   name: 'apps-report-budgeting',
  //   component: () => import('@/views/apps/report/Budgeting.vue'),
  //   meta: {
  //     pageTitle: 'apps.reportBudgeting.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.ReportBudgeting
  //   }
  // },
  {
    path: '/report/purchase/invoice-detail',
    name: 'apps-report-purchase-invoice-detail',
    component: () => import('@/views/apps/report/PurchaseInvoiceDetail.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseInvoiceDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseInvoiceDetail
    },
    props: route => ({
      dateUntil: route.query.dateUntil,
      contactId: route.query.contactId,
      type: route.query.type,
      tags: route.query.tags
    })
  },
  {
    path: '/report/profit-loss',
    name: 'apps-report-profit-loss',
    component: () => import('@/views/apps/report/ProfitLoss.vue'),
    meta: {
      pageTitle: 'apps.reportProfitLoss.moduleName',
      action: 'read',
      resource: subjectAbility.ReportProfitLoss
    }
  }
]
