import { subjectAbility } from '@/constant'

export default [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/apps/auth/Login.vue'),
    meta: {
      layout: 'full',
      shouldLogin: false,
      action: 'read',
      resource: subjectAbility.General,
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/apps/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      shouldLogin: false,
      action: 'read',
      resource: subjectAbility.General
    }
  }
]
  