/* eslint-disable no-unused-vars */
import request from '@/utils/request'

const state = {
  obj: []
}

const actions = {
  post ({ commit }, payload) {
    const { $toast, component } = payload.toasts
    return new Promise((resolve, reject) => {
      request({
        url: payload.url,
        method: 'post',
        data: payload.data || {}
      })
        .then(response => resolve(response))
        .catch(({ message }) => {
          $toast({
            component,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: message
            }
          })
          reject(message)
        })
    })
  },
  get ({ commit }, payload) {
    const { $toast, component } = payload.toasts
    return new Promise((resolve, reject) => {
      request({
        url: payload.url,
        method: 'get',
        data: payload.data || {}
      })
        .then(response => resolve(response))
        .catch(({ message }) => {
          $toast({
            component,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: message
            }
          })
          reject(message)
        })
    })
  },
  put ({ commit }, payload) {
    const { $toast, component } = payload.toasts
    return new Promise((resolve, reject) => {
      request({
        url: payload.url,
        method: 'put',
        data: payload.data || {}
      })
        .then(response => resolve(response))
        .catch(({ message }) => {
          $toast({
            component,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: message
            }
          })
          reject(message)
        })
    })
  },
  delete ({ commit }, payload) {
    const { $toast, component } = payload.toasts
    return new Promise((resolve, reject) => {
      request({
        url: payload.url,
        method: 'delete'
      })
        .then(response => {
          resolve(response)
        })
        .catch(({ message }) => {
          $toast({
            component,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: message
            }
          })
          reject(message)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions
}
